import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from 'Globals';
import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-global-risk',
  templateUrl: './global-risk.component.html',
  styleUrls: ['./global-risk.component.css']
})
export class GlobalRiskComponent implements OnInit {

  title = 'our Etoro Portfolio!';
  tGain;
  tLaunch;
  nsdqVal;

  stampForGraph: any;

  username;
  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];

  finalGains = [];
  finalMonthly = [];
  DynamUpd;
  currMonth: Date = new Date();

  mobile: Boolean = false;
  public options: any = {

    chart: {
      type: 'line'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]


  }

  constructor(
    private http: HttpClient, public nav: NavbarService, private meta: Meta
  ) { }

  ngOnInit() {
    this.username = "cosmicetoro"
    this.submit();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;;
    }
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth()+1), d.getFullYear()].join('/')
  }


  async submit() {
    this.riskAvg = 0;
    await this.getRisk();
  }

  public riskOptions: any = {

    chart: {
      type: 'column'
    },
    title: {
      text: 'Column chart with negative values'
    },
    xAxis: {
      categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 60,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [{
      name: 'John',
      data: [5, 3, 4, 7, 2]
    }, {
      name: 'Jane',
      data: [2, -2, -3, 2, 1]
    }, {
      name: 'Joe',
      data: [3, 4, 4, -2, 5]
    }]

  }

  riskSet = [];
  parsedRisk = [];
  riskValues = [];
  riskTimes = [];
  riskAvg = 0;
  riskText;

  async getRisk() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/History', { headers }).subscribe(data => {
      //console.log('data', data);
      this.riskSet = data;
      // console.log(this.riskSet)
      this.parsedRisk = this.riskSet["riskScore"];

      this.riskSet.forEach(i => {
        this.riskValues.push(i['riskScore']);
        this.riskAvg = this.riskAvg + i['riskScore'];
        this.riskTimes.push(i['timestamp']);
      });
      // console.log(this.riskValues);
      // console.log(this.riskTimes);

      this.riskAvg = this.riskAvg / this.riskValues.length;
      let buff = this.riskAvg.toString(); //If it's not already a String
      buff = buff.slice(0, (buff.indexOf(".")) + 3);
      this.riskText = buff;
      //console.log(this.riskAvg);

      this.populateRisk();
      //console.log(this.perfMonthly)
      this.riskSet = [];
      this.parsedRisk = [];
      this.riskValues = [];
      this.riskTimes = [];
    })
  }

  populateRisk() {
    var dateArr = [];
    var date;

    for (var i = 0; i <= this.riskTimes.length; i++) {
      date = new Date(this.riskTimes[i]);
      dateArr.push(this.convertDate(date))
      // console.log(dateArr[i]);
    }

    let max = 0;
    for (let i = 0; i <= this.riskValues.length - 1; i++) {
      if (max <= this.riskValues[i]) {
        max = this.riskValues[i];
      }
    }

    let start = 0;
    let end = dateArr.length - 2;
    let mid = Math.round((start+end)/2);

    let distance = end - mid; 
    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        type: 'column'
      },
      yAxis: {
        min: 2
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: true, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      title: {
        text: '',
      },
      plotOptions: {
        column: {
          zones: [{
            value: max, // Values up to 10 (not including) ...
            color: '#A5FF70' // ... have the color blue.
          }, {
            color: '#FFD754' // Values from 10 (including) and up have the color red
          }]
        }
      },
      series: [{
        name: 'Risk Score',
        data: this.riskValues
      }]

    }
    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 3;
    }
    Highcharts.chart('containerRisk', this.options);
  }



  logout(){
    localStorage.removeItem('username');
    location.reload();
  }

}





