import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';
import * as Highcharts from 'highcharts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from 'Globals';
import { Router } from '@angular/router';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit, AfterContentChecked {
  tGain;
  tLaunch;
  nsdqVal;

  stampForGraph: any;

  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];

  finalGains = [];
  finalMonthly = [];

  public options: any = {

    chart: {
      type: 'line'
    },
    title: {
      text: 'Our Monthly Gains to Date'
    },

    xAxis: {
      categories: ['Green', 'Pink']
    },

    series: [{
      data: [{
        name: 'Point 1',
        color: '#00FF00',
        y: 1
      }, {
        name: 'Point 2',
        color: '#FF00FF',
        y: 5
      }]
    }]


  }

  constructor(
    private http: HttpClient, public nav: NavbarService, private meta: Meta, private router: Router
  ) { }

  mobile: boolean = false;
  username;
  usernameDynam;
  
  ngOnInit(): void {
    this.meta.addTags([
      { name: 'title', content: 'Backseat Trader: Monthly Newsletter'},
      { name: 'description', content: 'Backseat Trader empowers investors with data-driven insights, a proprietary trading algorithm, and eToro integration for optimised returns. Access education & community support.' }
    ]);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.nav.hideSet(true);

    this.username = localStorage.getItem('username');

    if(this.username == null){
      this.usernameDynam = false;
    }else{
      this.usernameDynam = true;
      this.router.navigateByUrl("/user")
    }

    // console.log(this.username)
    // console.log(this.usernameDynam)

    // const element = document.getElementById("chartSize");
    // console.log("Width: " + element.offsetWidth + "px");
    // console.log("Height: " + element.offsetHeight + "px");

    // document.getElementById('benchmarkPlotImg').style.width = element.offsetWidth.toString() + "px";
    // document.getElementById('executionsPlot').style.width = element.offsetWidth.toString() + "px";

    // console.log(localStorage.getItem("chartSize"))

    // document.getElementById('benchmarkPlotImg').style.width = localStorage.getItem("chartSize").toString() + "px";


    this.submit();
    
  }

  removed: boolean = false;
  ngAfterContentChecked(): void {
    if(this.removed == false){
      if(document.querySelector('a[href*="https://elfsight.com"]')){
        document.querySelector('a[href*="https://elfsight.com"]').remove();
        this.removed = true;
      }
    }
  }

  // ngAfterContentChecked(): void {
  //   if(document.querySelectorAll('a[href*="https://"]').length == 37){
  //     console.log(document.querySelectorAll('a[href*="https://"]'))
  //     document.querySelectorAll('a[href*="https://"]')[35].remove();
  //   }
  // }

  nPos = false;
  async getNSDQ() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/nsdqval', { headers }).subscribe(data => {
      data.forEach(number => {
        // console.log(number);
        this.nsdqVal = number.nsdq_number;
        if (this.nsdqVal < 0) {
          this.nPos = false;
          // document.getElementById("tNSDQ").className = "legendRed";
        } else {
          this.nPos = true;
          // document.getElementById("tNSDQ").className = "legendGreen";
        }
      })
    })


  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth()+1), d.getFullYear()].join('/')
  }


  async submit() {

    //console.log(this.username);
    await this.getMonthlyGains();

    await this.getNSDQ();

  }

  cosmicGains = [];
  cosmicParsed = [];
  cosmicMonthly = [];

  dailyDate;
  dailyVal;
  monthlyVal;

  async getMonthlyGains() {

    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    var params = {
      // Request parameters
      "Type": "Daily",
      "MinDate": "2023-08-01",
      "MaxDate": "2023-09-01",
  };
  if(this.usernameDynam){
    // console.log("here")
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/DailyGain?', { headers, params }).subscribe(data => {
      // console.log('data', data);

      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(data[data.length - 1].timestamp)

      this.dailyDate = [d.getDate(),pad(d.getMonth()+1), d.getFullYear()].join('/')
      this.dailyVal = data[data.length - 1].gain;
    })    
    
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Gain?', { headers }).subscribe(data => {
      // console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });

      this.monthlyVal = this.gainsMonthly[this.gainsMonthly.length - 1]
      // console.log(this.timestampMonthly)
      this.populateGraphWithDynamicValues();
      this.monthlyGain = [];
      this.parsedMonthly = [];
      this.gainsMonthly = [];
      this.timestampMonthly = [];
    })
  
    await this.http.get<any>('https://api.etoro.com/API/User/V1/cosmicetoro/Gain?', { headers }).subscribe(data => {
      this.cosmicMonthly = data;
      this.cosmicParsed = this.cosmicMonthly["monthly"];
      //console.log(this.parsedMonthly);

      this.cosmicParsed.forEach(i => {
        this.cosmicGains.push(i['gain']);
      })

      this.cosmicGains = this.cosmicGains.reverse();
      // console.log(this.cosmicGains)

      // this.cosmicGains.length = 4;

      // console.log(this.cosmicGains)

    })
  }
  }

  gPos = false;
  lPos = false;
  populateGraphWithDynamicValues() {
    var dateArr = [];
    var date;

    // console.log(this.gainsMonthly)
    // console.log(this.cosmicGains)
    // console.log(this.cosmicGains.length)
    // console.log(this.gainsMonthly.length)
    
    // console.log(this.timestampMonthly[0])

    let initialDate = new Date(this.timestampMonthly[0])
    // console.log(initialDate.getMonth() + 1 + "/" + initialDate.getFullYear())

    if(this.gainsMonthly.length >= 6){
      for (var i = 0; i <= this.timestampMonthly.length; i++) {
        date = new Date(this.timestampMonthly[i]);
        dateArr.push(this.convertDate(date).toString())
      }
    }else{
      for (var i = 0; i <= this.timestampMonthly.length; i++) {
        date = new Date(this.timestampMonthly[i]);
        dateArr.push(this.username + " " + this.convertDate(date).toString())
      }
    }



    // console.log(this.cosmicGains)

    if(this.gainsMonthly.length == 0){
      this.cosmicGains.length = 6;
    }else if(this.gainsMonthly.length == 1){
      for(let i = 0; i<= this.gainsMonthly.length -1; i++){
        this.cosmicGains.shift();
      }
      this.cosmicGains.length = 5;
    }else if(this.gainsMonthly.length == 2){
      for(let i = 0; i<= this.gainsMonthly.length -1; i++){
        this.cosmicGains.shift();
      }
      this.cosmicGains.length = 4;
    }else if(this.gainsMonthly.length == 3){
      for(let i = 0; i<= this.gainsMonthly.length -1; i++){
        this.cosmicGains.shift();
      }
      this.cosmicGains.length = 3;
    }else if(this.gainsMonthly.length == 4){
      for(let i = 0; i<= this.gainsMonthly.length -1; i++){
        this.cosmicGains.shift();
      }
      this.cosmicGains.length = 2;
    }else if(this.gainsMonthly.length == 5){
      for(let i = 0; i<= this.gainsMonthly.length -1; i++){
        this.cosmicGains.shift();
      }
      this.cosmicGains.length = 1;
    }else if(this.gainsMonthly.length >= 6){
      this.cosmicGains.length = 0;
    }

    if(this.gainsMonthly.length <= 6){
      
      // console.log("here")
      let x = this.gainsMonthly.length;
      // this.cosmicGains.slice(0, (this.cosmicGains.length + 1 - this.gainsMonthly.length))
      // this.cosmicGains.splice(this.cosmicGains.length - x);
      // console.log(this.cosmicGains)
      for(let i = 0; i <= this.cosmicGains.length - 1; i++){
        this.gainsMonthly.unshift(this.cosmicGains[i])
      }
      // this.gainsMonthly.unshift(this.cosmicGains)
    }



    if(this.cosmicGains.length > 1){
      for(let i = 0; i<= this.cosmicGains.length - 1; i++){
        dateArr.unshift("cosmicetoro " + initialDate.getMonth() + "/" + initialDate.getFullYear())
        initialDate.setMonth(initialDate.getMonth() - 1);
      }
    }


    // let firstDate = dateArr.shift();

    // let first = this.gainsMonthly.shift();
    let initialise = this.gainsMonthly[0];

    //console.log(this.gainsMonthly);
    let plot = [];
    initialise = (initialise * 12 / 1);
    // console.log(initialise);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= this.gainsMonthly.length - 1; i++) {
      if (i == 1) {
        buff.push(this.gainsMonthly[i] + this.gainsMonthly[i - 1]);
      } else {
        buff.push(this.gainsMonthly[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = this.gainsMonthly[0];

    // console.log(buff);
    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    // console.log(buff);

    this.tGain = buff[buff.length -1];
    // console.log(this.tGain)
    let x = this.tGain.toPrecision(3);
    // console.log(x)
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(2);
    this.tLaunch = z;

    if (this.tGain < 0) {
      this.gPos = false;
      // document.getElementById("tGain").className = "legendRed";
    } else {
      this.gPos = true;
      // document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      // document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      // document.getElementById("tLaunch").className = "legendGreen";
    }

    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    // console.log(sum, "sum");

    let avg = sum / buff.length - 1;

    // console.log(avg, "avg");

    let agr = sum * 12 / 8;
    // console.log(agr);

    // for(var index = 0; index <= this.gainsMonthly.length; index++){
    //   this.gainsMonthly[index] = this.gainsMonthly[index] * 100;
    // }



    let plotBandText;
    let plotBandPos;
    let plotBandY;
    let plotBandTextCol;
    let plotBandAlign;
    let plotBandLabelText;
    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      plotBandText = "74%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '3';
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      gridOrNot = 0;
    } else {
      plotBandText = "100%";
      plotBandPos = 'middle';
      plotBandY = 0;
      plotBandTextCol = '#44AAD5';
      plotBandAlign = 'center';
      // plotBandLabelText = 'Targeted Gain Range <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of 15 - 25%';
      plotBandLabelText = 'Targeted Gain Range of 15 - 25%*';
      splineWidth = '5';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    }



    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            // pointDistance = Math.sqrt(
            //   (point0.plotX - point1.plotX) * (point0.plotX - point1.plotX) +
            //   (point0.plotY - point1.plotY) * (point0.plotY - point1.plotY)
            // );

            // angle = 90 + Math.atan2((point0.plotY - point1.plotY), (point0.plotX - point1.plotX)) * 180 / Math.PI;

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            // console.log(angle);

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            // console.log(calculatedPath)
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#1F85DE',
                // 'stroke-width': 2,

              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }



          }

          // }
        },

        type: 'column',

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        categories: dateArr,
        labels: {
          enabled: dateOrNot, //to hide the dates
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true

      }, { // Secondary yAxis

        title: {
          text: 'Monthly Return - (Target > 1.25%)',
          style: {
            color: Highcharts.getOptions().colors[2],
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        tickPositioner: function(min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);
            
          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max+step)); //hidden - added for top padding
          
          return ticks;
        },
        gridLineWidth: gridOrNot

      }, { 
        gridLineWidth: gridOrNot,
        max: 40,
        tickInterval: 15,
        plotBands: [{
          from: 15,
          to: 25,
          color: 'rgba(68, 170, 213, 0.2)',
          label: {
            // text: plotBandLabelText,
            verticalAlign: plotBandPos,
            align: plotBandAlign,
            y: plotBandY,
            style: {
              fontSize: plotBandText,
              fontWeight: 'bold',
              color: plotBandTextCol,

            }
          }
        }],
        title: {
          text: 'Annualised Gain Rate - (Target 15% - 25%)',
          style: {
            color: "#44AAD5"
          }
        },
        labels: {
          format: '{value}%',
          style: {
            // color: Highcharts.getOptions().colors[0]
            color: "#44AAD5"
          }
        },
        opposite: true
      }],

      title: {
        text: '',
      },
      plotOptions: {
        column: {
          maxPointWidth: 100,
          zones: [{
            value: 0, // Values up to 10 (not including) ...
            color: 'red' // ... have the color blue.
          }, {
            color: '#6AAC0E' // Values from 10 (including) and up have the color red
          }]
        },
        spline: {
          // shared options for all line series


          states: {
            hover: {
              enabled: false
            },


            inactive: {
              lineWidth: splineWidth,
            }
          }



        }
      },
      series: [{
        name: 'Monthly Gains',
        data: this.gainsMonthly,
        yAxis: 1,
      }, {
        yAxis: 2,
        type: 'spline',
        dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Annualised Gain Rate',
        color: '#1F85DE',
        data: buff,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,

          //   symbol: 'triangle', 
          // fillColor: 'rgba(126,86,100,.9)', /* match to the color of your column */
          // radius:25
        }
      }]

    }
    Highcharts.chart('container', this.options);
  }

}
