import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {

  constructor(private nav: NavbarService, private meta: Meta) { }

  mobile: Boolean = false;

  ngOnInit(): void {
    this.meta.addTags([
      { name: 'title', content: 'Backseat Trader: Website Sitemap'},
      { name: 'description', content: 'Backseat Trader empowers investors with data-driven insights, a proprietary trading algorithm, and eToro integration for optimised returns. Access education & community support.' }
    ]);
    this.nav.hideSet(true);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

}
