import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollIntoView]'
})
export class ScrollIntoViewDirective {

  constructor(private el: ElementRef) { }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const container = event.target as HTMLElement;
    const rect = this.el.nativeElement.getBoundingClientRect();
    // console.log(rect)

    // Check if the section is in view
    if (rect.top >= 0 && rect.bottom <= container.clientHeight) {
      // Scroll to the top of the section
      this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}