import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'app-global-news',
  templateUrl: './global-news.component.html',
  styleUrls: ['./global-news.component.css']
})
export class GlobalNewsComponent implements OnInit {

  constructor(private http: HttpClient) { }

  mobile: Boolean = false;

  async ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    await this.getMonthlyGains();
    await this.getBenchmark();
    await this.getExecutionBanner();
    await this.getExecutions();
  }

  username;
  monthlyGain = [];
  parsedMonthly = [];
  timestampMonthly = [];
  gainsMonthly = [];
  stampForGraph: any;
  tGain;
  tLaunch;
  gPos = false;
  lPos = false;

  updateRecent: any;

  public options: any = {

    // chart: {
    //   type: 'column'
    // },
    // title: {
    //   text: 'Our Monthly Gains to Date'
    // },

    // xAxis: {
    //   categories: ['Green', 'Pink']
    // },

    // series: [{
    //   data: [{
    //     name: 'Point 1',
    //     color: '#00FF00',
    //     y: 1
    //   }, {
    //     name: 'Point 2',
    //     color: '#FF00FF',
    //     y: 5
    //   }]
    // }],



  }

  async getMonthlyGains() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/cosmicetoro/Gain?', { headers }).subscribe(data => {
      // console.log('data', data);
      this.monthlyGain = data;
      this.parsedMonthly = this.monthlyGain["monthly"];
      //console.log(this.parsedMonthly);

      this.parsedMonthly.forEach(i => {
        this.gainsMonthly.push(i['gain']);
        this.timestampMonthly.push(i['timestamp']);
      });
      this.stampForGraph = this.timestampMonthly[this.timestampMonthly.length - 1];
      this.updateRecent = this.timestampMonthly[this.timestampMonthly.length - 1]
      // this.getTheDate(this.timestampMonthly);
      // console.log(this.timestampMonthly);
      // console.log(this.stampForGraph);
      // this.populateGraphWithDynamicValues();
      // this.monthlyGain = [];
      // this.parsedMonthly = [];
      // this.gainsMonthly = [];
      // this.timestampMonthly = [];
    })
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth() + 1), d.getFullYear()].join('/')
  }

  NSDQ100Arr;
  NSDQ100ArrFiltered = [];
  performanceArr;
  performanceArrFiltered = [];

  nPosBench: boolean = false;
  nBenchVal;

  ePosBench: boolean = false;
  eBenchVal;

  async getBenchmark() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/benchmark-values', { headers }).subscribe(data => {
      // console.log(data)
      this.NSDQ100Arr = data[0].NSDQ100;
      this.performanceArr = data[0].Performance;

      // console.log(this.NSDQ100Arr)
      // console.log(this.performanceArr)

      this.NSDQ100ArrFiltered = this.NSDQ100Arr.split(",");
      this.performanceArrFiltered = this.performanceArr.split(",");
      
      for(let i = 0; i <= this.performanceArrFiltered.length - 1; i++){
        this.performanceArrFiltered[i] = Number(this.performanceArrFiltered[i])
      }

      for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
        this.NSDQ100ArrFiltered[i] = Number(this.NSDQ100ArrFiltered[i]);
      }

      // console.log(this.gainsMonthly[this.gainsMonthly.length - 2])
      // console.log(this.NSDQ100ArrFiltered[this.NSDQ100ArrFiltered.length - 1])

      // this.nBenchVal = this.NSDQ100ArrFiltered[this.NSDQ100ArrFiltered.length - 1];
      // this.eBenchVal = this.gainsMonthly[this.gainsMonthly.length - 2];
      let nsdqSum = 0;
      for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
       nsdqSum += this.NSDQ100ArrFiltered[i];
      }
      this.nBenchVal = nsdqSum;

      let etoroSum = 0;
      for(let i = 0; i<= this.gainsMonthly.length - 2; i++){
        // console.log(this.gainsMonthly[i])
        etoroSum += this.gainsMonthly[i]
      }

      this.eBenchVal = etoroSum;


      if(this.nBenchVal >= 10){
        this.nBenchVal = this.nBenchVal.toFixed(1);
      }else{
        this.nBenchVal = this.nBenchVal.toFixed(2);
      }

      if(this.eBenchVal >= 10){
        this.eBenchVal = this.eBenchVal.toFixed(1);
      }else{
        this.eBenchVal = this.eBenchVal.toFixed(2);
      }


      if (this.nBenchVal < 0) {
        this.nPosBench = false;
        document.getElementById("tNSDQBench").className = "legendRed";
      } else {
        this.nPosBench = true;
        document.getElementById("tNSDQBench").className = "legendGreen";
      }

      if (this.eBenchVal < 0) {
        this.ePosBench = false;
        document.getElementById("eBenchID").className = "legendRed";
      } else {
        this.ePosBench = true;
        document.getElementById("eBenchID").className = "legendGreen";
      }

      this.populateBenchmark();

    })

  }

  benchmarkDate = new Date;
  benchmarkDateStr;

  populateBenchmark() {

    this.benchmarkDate.setDate(1); // going to 1st of the month
    this.benchmarkDate.setHours(-1); // going to last hour before this date even started.

    var dobArr = this.benchmarkDate.toDateString().split(' ');
    this.benchmarkDateStr = dobArr[2] + ' ' + dobArr[1] + ' ' + dobArr[3];

    var dateArr = [];
    var date;

    let months = this.timestampMonthly;
    let etoro = this.gainsMonthly;
    etoro.pop();
    months.pop();

    let max = 0;
    let maxEtoro = 0;

    let min = 0;

    let combined = [];

    for(let i = 0; i<= this.performanceArrFiltered.length - 1; i++){
      if(this.performanceArrFiltered[i] > max){
        max = this.performanceArrFiltered[i];
      }
    }

    max = Math.round(max / 10) * 10;

    for(let i = 0; i<= etoro.length - 1; i++){
      combined.push(etoro[i])
      // console.log(etoro[i])
      if(etoro[i] > maxEtoro){
        maxEtoro = etoro[i]
      }
    }

    for(let i = 0; i<= this.NSDQ100ArrFiltered.length - 1; i++){
      combined.push(this.NSDQ100ArrFiltered[i]);
    }

    // console.log(combined)

    const negativeInts = combined.filter(i => i < 0)

    min = Math.min(...negativeInts)

    min = min * -1

    min = Math.ceil(min / 5) * 5;

    min = -Math.abs(min)
    // console.log(min)


    for (var i = 0; i <= months.length; i++) {
      // console.log(true)
        
        // if(i % 4 == 0){
        //   console.log(true)
          // this.timestampMonthly[i] == "";
          date = new Date(months[i]);
          dateArr.push(this.convertDate(date))
        // }else{
        //   dateArr.push("")

        // }

      
    }

    let firstDate = dateArr.shift();

    let start = 0;
    let end = dateArr.length - 2;
    let mid = Math.round((start+end)/2);

    let initialise = etoro[0];

    let plot = [];
    initialise = (initialise * 12 / 1);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= etoro.length - 1; i++) {
      if (i == 1) {
        buff.push(etoro[i] + etoro[i - 1]);
      } else {
        buff.push(etoro[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = etoro[0];

    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    this.tGain = buff[7];
    let x = this.tGain.toPrecision(3);
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(2);
    this.tLaunch = z;

    if (this.tGain < 0) {
      document.getElementById("tGain").className = "legendRed";
      this.gPos = false;
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }


    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    let avg = sum / buff.length - 1;

    let agr = sum * 12 / 8;

    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      splineWidth = '3';
      gridOrNot = 0;
    } else {
      splineWidth = '3';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    }

    // console.log(etoro)



    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#01B150',
              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }
          }
        },

        type: 'column',

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: true, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      yAxis: [{ // Primary yAxis

        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true,

      }, { // Secondary yAxis
        visible: false,
        // title: {
        //   text: 'Monthly Return - (Target > 1.25%)',
        //   style: {
        //     color: Highcharts.getOptions().colors[2],
        //   }
        // },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        tickPositioner: function (min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);

          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max + step)); //hidden - added for top padding

          return ticks;
        },
        gridLineWidth: gridOrNot,
        linkedTo: 2,
        max: maxEtoro,
      }, {
        title: {
          text: ''
        },
        gridLineWidth: 1,
        max: max,
        min: min,
        startOnTick: false,
        endOnTick: false,
        labels: {
          format: '{value}%',
          style: {
            color: "black"
          }
        },
        opposite: false,
      }],

      title: {
        text: '',
      },
      plotOptions: {
        spline: {
          // shared options for all line series
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              lineWidth: splineWidth,
            }
          }
        }
      },
      series: [{
        name: 'eToro',
        data: etoro,
        yAxis: 1,
        color: "#FEDF7E"
      }, {
        yAxis: 2,
        type: 'spline',
        dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Performance +/-',
        color: "#01B150",
        data: this.performanceArrFiltered,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,
        }
      },{
        name: 'NSDQ100',
        data: this.NSDQ100ArrFiltered,
        yAxis: 1,
        color: "#7ED7F6"
      }]

    }
    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 3;
    }
    Highcharts.chart('benchmarkContainer', this.options);

    
  }

  gainArr;
  gainArrFiltered = [];
  closedArr;
  closedArrFiltered = [];
  datesArr;
  datesArrFiltered = [];

  async getExecutions() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/executions-values', { headers }).subscribe(data => {
      // console.log(data)
      this.closedArr = data[0].Closed;
      this.gainArr = data[0].GainLoss;
      this.datesArr = data[0].Dates;

      // console.log(this.NSDQ100Arr)
      // console.log(this.performanceArr)

      this.gainArrFiltered = this.gainArr.split(",");
      this.closedArrFiltered = this.closedArr.split(",");
      this.datesArrFiltered = this.datesArr.split(",");
      
      for(let i = 0; i <= this.gainArrFiltered.length - 1; i++){
        this.gainArrFiltered[i] = Number(this.gainArrFiltered[i])
      }

      for(let i = 0; i<= this.closedArrFiltered.length - 1; i++){
        this.closedArrFiltered[i] = Number(this.closedArrFiltered[i]);
      }


      let day;
      let month;
      let year;

      for(let i = 0; i<= this.datesArrFiltered.length - 1; i++){
        day = this.datesArrFiltered[i].substring(0,2);
        month = this.datesArrFiltered[i].substring(3,5);
        year = this.datesArrFiltered[i].substring(6,10);

        this.datesArrFiltered[i] = year + "/" + month + "/" + day;
      }

      // console.log(this.gainArrFiltered)
      // console.log(this.closedArrFiltered)
      

      this.populateExe();

    })

  }

  lastDate;

  populateExe() {
    var dateArr = [];
    var date;

    let maxGains = 0;
    let maxClosed = 0;

    for(let i = 0; i <= this.gainArrFiltered.length - 1; i++){
      if(this.gainArrFiltered[i] > maxGains){
        maxGains = this.gainArrFiltered[i];
      }
    }

    for(let i = 0; i <= this.closedArrFiltered.length - 1; i++){
      if(this.closedArrFiltered[i] > maxClosed){
        maxClosed = this.closedArrFiltered[i];
      }
    }

    // maxClosed = Math.round(maxClosed / 10) * 10;
    maxClosed = maxClosed + 1;
    maxGains = maxGains + 2;

    // console.log(this.datesArrFiltered)

    for (var i = 0; i <= this.datesArrFiltered.length - 1; i++) {
      // console.log(true)
        
        // if(i % 6 == 0){
        //   console.log(true)
          // this.timestampMonthly[i] == "";
          // date = new Date(this.timestampMonthly[i]);
          date = new Date(this.datesArrFiltered[i]);
          // console.log(date)
          dateArr.push(this.convertDate(date))
        // }else{
        //   // dateArr.push(undefined)

        // }

      
    }

    this.lastDate = dateArr[dateArr.length - 1];
    
    let month = this.lastDate.substring(0,3);
    let year = this.lastDate.substring(3,7);
    if(month == '02/'){
      this.lastDate = month + "29/" + year;  
    }else if(month == '04/' || month == '06/' || month == '09/' || month == '11/'){
    this.lastDate = month + "30/" + year;
    }else{
      this.lastDate = month + "31/" + year;
    }
    let theDate = new Date(this.lastDate);
    this.lastDate = theDate.toLocaleString('default', { month: 'long' }) + " " + theDate.getFullYear();

    // let first = this.gainsMonthly.shift();
    let initialise = this.gainsMonthly[0];

    let plot = [];
    initialise = (initialise * 12 / 1);
    plot.push(initialise);
    let test = [2.88, 28.68, 37.80, 39.15, 17.23, 25.76, 24.94, 24.62, 10.01, 8.74, 10.65, 2.59, 2.86];
    let buff = [];

    for (var i = 0; i <= this.gainsMonthly.length - 1; i++) {
      if (i == 1) {
        buff.push(this.gainsMonthly[i] + this.gainsMonthly[i - 1]);
      } else {
        buff.push(this.gainsMonthly[i] + buff[i - 1]);
      }

      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;

    }

    buff[0] = this.gainsMonthly[0];

    // console.log(buff);
    let y = 1;

    for (var i = 0; i <= buff.length - 1; i++) {
      buff[i] = (buff[i] * 12 / y);
      y++;
      buff[i] = Math.round((buff[i] + Number.EPSILON) * 100) / 100;
    }

    this.tGain = buff[7];
    let x = this.tGain.toPrecision(3);
    this.tGain = x;
    this.tLaunch = buff[buff.length - 1];
    let z = this.tLaunch.toPrecision(2);
    this.tLaunch = z;

    if (this.tGain < 0) {
      document.getElementById("tGain").className = "legendRed";
      this.gPos = false;
    } else {
      this.gPos = true;
      document.getElementById("tGain").className = "legendGreen";
    }

    if (this.tLaunch < 0) {
      this.lPos = false;
      document.getElementById("tLaunch").className = "legendRed";
    } else {
      this.lPos = true;
      document.getElementById("tLaunch").className = "legendGreen";
    }


    let sum = 0;

    for (var i = 0; i <= 8; i++) {
      sum += buff[i];
    }

    let avg = sum / buff.length - 1;

    let agr = sum * 12 / 8;

    let splineWidth;
    let arrowLengthDyn;
    let arrowWidthDyn;
    let dateOrNot;
    let gridOrNot;

    if (this.mobile == true) {
      arrowLengthDyn = 7.5;
      arrowWidthDyn = 4;
      dateOrNot = false;
      splineWidth = '3';
      gridOrNot = 0;
    } else {
      splineWidth = '3';
      arrowLengthDyn = 15;
      arrowWidthDyn = 8;
      dateOrNot = true;
      gridOrNot = 0;
    }

    this.gainArrFiltered.splice(0, 207);
    this.closedArrFiltered.splice(0,207);
    dateArr.splice(0,207);

    let start = 0;
    let end = dateArr.length - 1;
    let mid = Math.round((start+end)/2);

    // Try to change the type of grpah, it will work the way it is, need to adjus the values :)
    this.options = {
      chart: {
        events: {
          load: function () {
            this.customArrows = [];
          },
          render: function () {
            const chart = this,
              series1Data = chart.series[1].data;
            let point0, calculatedPath;
            point0 = series1Data[series1Data.length - 1];
            var arrowLength = arrowLengthDyn,
              arrowWidth = arrowWidthDyn,
              lastPoint = point0,
              nextLastPoint = series1Data[series1Data.length - 2],
              angle = Math.atan((lastPoint.plotX - nextLastPoint.plotX) /
                (lastPoint.plotY - nextLastPoint.plotY)),
              path = [];

            if (angle < 0) {
              angle = Math.PI + angle;
            }

            // pointDistance = Math.sqrt(
            //   (point0.plotX - point1.plotX) * (point0.plotX - point1.plotX) +
            //   (point0.plotY - point1.plotY) * (point0.plotY - point1.plotY)
            // );

            // angle = 90 + Math.atan2((point0.plotY - point1.plotY), (point0.plotX - point1.plotX)) * 180 / Math.PI;

            path.push('M', lastPoint.plotX, lastPoint.plotY);
            path.push(
              'L',
              lastPoint.plotX + arrowWidth * Math.cos(angle),
              lastPoint.plotY - arrowWidth * Math.sin(angle)
            );
            path.push(
              lastPoint.plotX + arrowLength * Math.sin(angle),
              lastPoint.plotY + arrowLength * Math.cos(angle)
            );
            path.push(
              lastPoint.plotX - arrowWidth * Math.cos(angle),
              lastPoint.plotY + arrowWidth * Math.sin(angle),
              'Z'
            );

            // console.log(angle);

            calculatedPath = path;

            if (!point0.customArrow) {
              point0.customArrow = chart.renderer.path().add(chart.series[1].group);
            }
            // console.log(calculatedPath)
            if (chart.series[1].visible && chart.series[0].visible) {
              point0.customArrow.attr({
                d: calculatedPath,
                fill: '#FEC71C',
                // 'stroke-width': 2,

              });
            } else {
              point0.customArrow.attr({
                d: ['M', -10, -10, 'L', -10, -10]
              });
            }
          }
        },

        // zoomType: 'x',
        type: 'column'

      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 200,
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      xAxis: {
        max: end,
        categories: dateArr,
        showLastLabel: true,
        labels: {
          enabled: true, //to hide the dates
          // step: steps,
          style: {
            // fontSize: "2em",
            color: "black",
            fontWeight: "bold"
          },
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        opposite: true,

      }, { // Secondary yAxis

        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2],
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: Highcharts.getOptions().colors[0]
          },
        },
        tickPositioner: function (min, max) {
          var ticks = [],
            tick = min,
            step = Math.round((max - min) / 7);

          while (tick < max - step / 2) {
            ticks.push(Math.round(tick));
            tick += step;
          }
          ticks.push(Math.round(max));
          ticks.push(Math.round(max + step)); //hidden - added for top padding

          return ticks;
        },
        max: maxClosed,
        startOnTick: false,
        endOnTick: false,
        plotLines: [{
          value: 0,
          width: 1,
          color: '#aaa',
          zIndex: 10
        }],
        lineColor: Highcharts.getOptions().colors[0],
        lineWidth: 4,
        gridLineWidth: gridOrNot
      }, {
        gridLineWidth: gridOrNot,
        // linkedTo:1,
        max: maxGains,
        startOnTick: false,
        endOnTick: false,
        tickInterval: 15,
        title: {
          text: '',
          style: {
            color: "#FEC71C"
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: "#FEC71C"
          },
        },
        opposite: true,
        lineColor: "#FEC71C",
        lineWidth: 4,
        
      }],

      title: {
        text: '',
      },
      plotOptions: {
        // column: {
          // maxPointWidth: 100,
        //   zones: [{
        //     value: 0, // Values up to 10 (not including) ...
        //     color: 'red' // ... have the color blue.
        //   }, {
        //     color: '#6AAC0E' // Values from 10 (including) and up have the color red
        //   }]
        // },
        line: {
          // shared options for all line series
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              lineWidth: splineWidth,
            }
          }
        }
      },
      series: [{
        name: 'Closed Trades',
        data: this.closedArrFiltered,
        yAxis: 1,
        color: "#B0D2F4"
      }, {
        yAxis: 2,
        type: 'line',
        // dashStyle: 'longdash',
        lineWidth: splineWidth,
        name: 'Gain / Loss Rate',
        color: '#FEC71C',
        data: this.gainArrFiltered,
        marker: {
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white',
          enabled: false,

          //   symbol: 'triangle', 
          // fillColor: 'rgba(126,86,100,.9)', /* match to the color of your column */
          // radius:25
        }
      }]

    }
    if(this.mobile){
      this.options.xAxis.tickPositions = [start, mid, end];
    }else if(!this.mobile){
      this.options.xAxis.labels.step = 100;
    }
    Highcharts.chart('exeContainer', this.options);
  }

  unrealisedGPos: boolean = false;
  netAssetPos: boolean = false;

  unrealisedG;
  netAsset;

  async getExecutionBanner() {
    const headers = {
      'cache-control': 'no-cache',
      'x-apikey': '63176c540b7c265b2a0e7818'
    }
    await this.http.get<any>('https://cosmicmedia-a0ce.restdb.io/rest/executions-dialog', { headers }).subscribe(data => {
      data.forEach(number => {
        // console.log(number);

        this.unrealisedG = number.UnrealisedGain;
        this.netAsset = number.NetAssetValue;
        
       
        if (this.unrealisedG < 0) {
          this.unrealisedGPos = false;
          document.getElementById("unrealised").className = "legendRed";
        } else {
          this.unrealisedGPos = true;
          document.getElementById("unrealised").className = "legendGreen";
        }

        if(this.unrealisedG == 0){
          this.unrealisedG = "0.00";
        }

        if (this.netAsset < 0) {
          this.netAssetPos = false;
          document.getElementById("netasset").className = "legendRed";
        } else {
          this.netAssetPos = true;
          document.getElementById("netasset").className = "legendGreen";
        }

        if(this.netAsset == 0){
          this.netAsset = "0.00";
        }

      })
    })


  }

  

}
