import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  // public filesurl = 'http://localhost:8088/'; //sri
  public filesurl= 'https://theapi.cosmic-algorithm.ie/'

  //public url = this.filesurl + 'v1/'
  public url = this.filesurl
}
